import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <nav className="site-nav">
      <ul>
        <li>
          <Link className="color-blue" activeClassName="active" to="/">
            home
          </Link>
        </li>
        <li>
          <Link className="color-green" activeClassName="active" to="/projects/">
            projects
          </Link>
        </li>
        {/*<li>*/}
        {/*<Link className="color-brown" activeClassName="active" to="/blog">*/}
        {/*blog*/}
        {/*</Link>*/}
        {/*</li>*/}
        <li>
          <Link className="color-red" activeClassName="active" to="/contact/">
            contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Header
