import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Index = () => {
  return (
    <Layout section="presentation">
      <SEO
        title="Freelance Drupal Developer"
        description="Hey! I’m Carlos Sánchez, a Computer Engineer working as a freelance Drupal developer. I work remotely for companies all over the world"
      />
      <h1>
        <strong>Hey! I’m Carlos Sánchez,</strong>
        <br />a freelance Drupal developer.
      </h1>

      <p>
        I’m a Computer Engineer by the University of Zaragoza (Spain), working
        professionally with Drupal since 2011 and creating technical solutions
        for a wide variety of business challenges, which I really enjoy. On
        2014, I joint the{" "}
        <a
          href="https://www.recurse.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Recurse Center
        </a>{" "}
        in New York City with other passionate programmers. I try to keep up
        with their motto: <em>Never graduate!</em> Learning is my passion as I
        am intellectually curious about almost anything.
      </p>

      <p>
        I work remotely with companies all over the world, with
        multidisciplinary teams on medium- to big-size{" "}
        <Link to="/projects/">projects</Link>, using agile methodologies,
        well-defined git workflows, continuous integration systems and, of
        course, Slack :)
      </p>

      <p>
        If you need help with a project, please{" "}
        <Link to="/contact/">contact</Link> me.
      </p>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
