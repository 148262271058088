import React from "react"
import Header from "../components/header"
import "../styles/style.scss"

const Layout = props => {
  let main_class = "content"
  if (props.section) {
    main_class += " " + props.section
  }

  return (
    <div className="page-wrapper">
      <div className="console">
        <p>
          ><span className="cursor">_</span>
        </p>
      </div>

      <div className="page-content">
        <Header />
        <main className={`${main_class}`}>{props.children}</main>
      </div>
    </div>
  )
}

export default Layout
